import React, {useCallback} from 'react';
import {Button, ButtonGroup, Stack} from '@shopify/polaris';
import MigrationProgressBar from '../../migrationProgressBar/migrationProgressBar';
import {navigate} from "gatsby";
import './modalFooter.css';

const ModalFooter = (props) => {
  const {
    isDone,
    selectedResources,
    isLoading,
    setIsLoading,
    migrationProgress,
    migrateHandler,
    handleChangeModalActive,
    setActive,
  } = props;

  const doneHandler = useCallback(() => {
    navigate('/app/offers', {
      replace: true,
    })
    setActive(false)
  }, []);

  const progressBarStyles = {
    width: '83%',
    padding: '0 5%'
  }

  return (
    <Stack distribution='fill'>
      {
        isDone
          ? (
            <Stack distribution='trailing'>
              <Button loading={isLoading} primary onClick={doneHandler}>
                Done
              </Button>
            </Stack>
          )
          : (
            <div className='ModalFooterWrapper'>
              <MigrationProgressBar styles={progressBarStyles} progress={migrationProgress} isLoading={isLoading}/>
              <Stack distribution='trailing'>
                <ButtonGroup>
                  <Button loading={isLoading} onClick={handleChangeModalActive}>Cancel</Button>
                  <Button loading={isLoading} onClick={() => {
                    setIsLoading(true);
                    migrateHandler();
                  }} primary disabled={selectedResources?.length === 0}>Migrate</Button>
                </ButtonGroup>
              </Stack>
            </div>
          )
      }
    </Stack>
  );
};

export default ModalFooter;